var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 6] } },
        [
          _c("a-col", [
            _c("span", { staticClass: "text-subtitle-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("lbl_" + _vm.propItem.type)) + " ")
            ])
          ]),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 6 } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("lbl_product_code")))]),
              _vm.isModeCreate
                ? [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("lbl_choose"),
                          "show-search": "",
                          "allow-clear": "",
                          "filter-option": false,
                          disabled: _vm.isDisabled,
                          "dropdown-match-select-width": false
                        },
                        on: {
                          search: _vm.searchProductCode,
                          change: _vm.onselectProduct
                        },
                        model: {
                          value: _vm.form.productCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productCode", $$v)
                          },
                          expression: "form.productCode"
                        }
                      },
                      _vm._l(_vm.dtOpt.productCode, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.key, attrs: { value: item.value } },
                          [
                            _c(
                              "a-tooltip",
                              [
                                _c("template", { slot: "title" }, [
                                  _vm._v(" " + _vm._s(item.key || "-") + " ")
                                ]),
                                _vm._v(" " + _vm._s(item.key || "-") + " ")
                              ],
                              2
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                : _c("p", [
                    _vm._v(" " + _vm._s(_vm.form.productCode || "-") + " ")
                  ])
            ],
            2
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 6 } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("lbl_product_name")))]),
              _vm.isModeCreate
                ? [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("lbl_choose"),
                          "show-search": "",
                          "allow-clear": "",
                          disabled: _vm.isDisabled,
                          "filter-option": false,
                          "dropdown-match-select-width": false
                        },
                        on: {
                          search: _vm.searchProductName,
                          change: _vm.onselectProduct
                        },
                        model: {
                          value: _vm.form.productName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productName", $$v)
                          },
                          expression: "form.productName"
                        }
                      },
                      _vm._l(_vm.dtOpt.productName, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.key, attrs: { value: item.value } },
                          [
                            _c(
                              "a-tooltip",
                              [
                                _c("template", { slot: "title" }, [
                                  _vm._v(" " + _vm._s(item.key || "-") + " ")
                                ]),
                                _vm._v(" " + _vm._s(item.key || "-") + " ")
                              ],
                              2
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                : _c("p", [
                    _vm._v(" " + _vm._s(_vm.form.productName || "-") + " ")
                  ])
            ],
            2
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 6 } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("lbl_qty")))]),
              _vm.isModeCreate
                ? [
                    _c(
                      "div",
                      [
                        _c(
                          "a-space",
                          [
                            _c("a-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("lbl_type_here"),
                                formatter: _vm.formatterNumber,
                                parser: _vm.reverseFormatNumber,
                                precision: _vm.DECIMAL_PLACES_QTY,
                                min: 0,
                                disabled:
                                  !_vm.form.productCode ||
                                  !_vm.form.productName ||
                                  _vm.isDisabled
                              },
                              on: { change: _vm.emitData },
                              model: {
                                value: _vm.form.qty,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "qty", _vm._n($$v))
                                },
                                expression: "form.qty"
                              }
                            }),
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.form.productCode ||
                                    !_vm.form.productName ||
                                    _vm.isDisabled
                                },
                                on: { click: _vm.showModalScale }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_scale")) + " ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toDecimalQty")(_vm.form.qty || 0)) +
                        " "
                    )
                  ])
            ],
            2
          )
        ],
        1
      ),
      _vm.modal.scale.show
        ? _c("CScale", {
            on: { "on-save": _vm.onScaleSave },
            model: {
              value: _vm.modal.scale.show,
              callback: function($$v) {
                _vm.$set(_vm.modal.scale, "show", $$v)
              },
              expression: "modal.scale.show"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }