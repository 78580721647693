




























































































































import { ResponseListProduct } from "@interface/product.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { productService } from "@service/product.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { debounceProcess } from "@/helpers/debounce";
import { Mode } from "@/models/enums/global.enum";
import { DECIMAL_PLACES_QTY, DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { formatCurrency, formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";

export interface IFormPostProduct {
  productCode: string;
  productName: string;
  productId: string;
  qty: number;
}

export default Vue.extend({
  name: "JobCostingPostProduct",
  components: {
    CScale: () => import(/*webpackPrefetch: true */"@/components/shared/scale/CScale.vue"),
  },
  mixins: [
    MNotificationVue
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      required: false,
      default: undefined
    },
    propDetailDocument: {
      type: Object as () => { id: string, productCode: string, productId: string, productName: string, qty: number } | null,
      required: false,
      default: null
    },

  },
  data() {
    this.searchProductCode = debounceProcess(this.searchProductCode, 300);
    this.searchProductName = debounceProcess(this.searchProductName, 300);
    return {
      DECIMAL_PLACES_QTY,
      dtOpt: {
        productCode: [] as IOption[],
        productName: [] as IOption[],
      },
      form: {
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        productId: undefined as string | undefined,
        qty: undefined as number | undefined,
      },
      dtList: {
        product: {} as ResponseListProduct
      },
      modal: {
        scale: {
          show: false,
          data: {} as any
        }
      }
    };
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled;
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
  },
  mounted() {
    this.fetchListProduct();
    this.viewDetail();
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    onScaleSave({ value }): void {
      this.form.qty = value;
      this.emitData();
    },
    showModalScale(): void {
      this.modal.scale.show = true;
    },
    getListProduct(params: RequestQueryParamsModel): Promise<ResponseListProduct> {
      return productService.listProduct(params);
    },
    async fetchListProduct(): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: DEFAULT_PAGE_SIZE,
        };
        param.search = this.findProductByType(this.propItem.type);
        if (!param.search) delete param.search;
        const res = await this.getListProduct(param);
        this.dtOpt.productCode = res.data.map(x => { return { key: x.code, value: x.code }; });
        this.dtOpt.productName = res.data.map(x => { return { key: x.name, value: x.name }; });
        this.dtList.product = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    findProductByType(type: "bone" | "fat" | "rump" | "oxtail" | "trim"): string {
      let search = "";
      if (type === "bone") {
        search = "code~GPW-GPW-TLG-MIX";
      } else if (type === "fat") {
        search = "code~*FAT*";
      } else if (type === "rump") {
        search = "code~GPW-GPW-BGL-LOC";
      } else if (type === "oxtail") {
        search = "code~GPW-GPW-ENC-LOC#S";
      } else if (type === "trim") {
        search = "code~*TRM*";
      }
      return search;
    },
    async searchProductCode(search = ""): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: DEFAULT_PAGE_SIZE
        };
        if (search) param.search = `code~*${search}*`;
        const res = await this.getListProduct(param);
        this.dtOpt.productCode = res.data.map(x => { return { key: x.code, value: x.code }; });
        this.dtList.product = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async searchProductName(search = ""): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: DEFAULT_PAGE_SIZE
        };
        if (search) param.search = `name~*${search}*`;
        const res = await this.getListProduct(param);
        this.dtOpt.productName = res.data.map(x => { return { key: x.name, value: x.name }; });
        this.dtList.product = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onselectProduct(value: string | null): void {
      const prod = this.dtList.product.data.find(x => {
        return x.code === value || x.name === value;
      });
      this.form.productId = undefined;
      this.form.productCode = undefined;
      this.form.productName = undefined;
      this.form.qty = undefined;

      if (prod) {
        this.form.productId = prod.id;
        this.form.productName = prod.name;
        this.form.productCode = prod.code;
      }

      this.emitData();
    },
    emitData(): void {
      const payload = {
        data: this.form.qty && this.form.productCode && this.form.productName ? this.form : null,
        type: this.propItem.type
      };
      this.$emit("on-data-change", payload);
    },
    viewDetail(): void {
      if (!this.propDetailDocument) return;
      const { productCode, productName, productId, qty } = this.propDetailDocument;
      this.form = {
        productCode, productId, productName, qty
      };
    }
  }
});

